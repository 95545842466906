<template>
  <b-card>
    <h5 class="mb-2 text-primary">
      {{ title }}
    </h5>
    <app-timeline>
      <app-timeline-item
        title="Salida"
        :subtitle="travelData.source"
        icon="Navigation2Icon"
        :time="`Aprox ${travelData.departure_date.split(' ')[0]} - ${travelData.departure_date.split(' ')[1]}`"
        variant="primary"
      />

      <app-timeline-item
        title="Llegada"
        :subtitle="travelData.destination"
        icon="MapPinIcon"
        :time="`Aprox ${travelData.arrival_date.split(' ')[0]} - ${travelData.arrival_date.split(' ')[1]}`"
        variant="success"
      />
    </app-timeline>
    <hr v-if="isActivePay">
    <h5
      v-if="isActivePay"
      class="mb-2 text-primary"
    >
      Resumen de pago
    </h5>
    <b-list-group
      v-if="isActivePay"
      flush
    >
      <b-list-group-item
        v-if="travelData.cost_per_transaction > 0"
        class="d-flex justify-content-between align-items-center"
      >
        <span>Cargo por transacción</span>
        <span>$ {{ travelData.cost_per_transaction }} </span>
      </b-list-group-item>
      <b-list-group-item
        v-if="typePayment.transaction_fee > 0"
        class="d-flex justify-content-between align-items-center"
      >
        <span>Cargo por metodo de pago</span>
        <span><b>{{ typePayment.transaction_fee }}</b> %</span>
      </b-list-group-item>
      <b-list-group-item
        v-if="travelData.stamp_fee > 0"
        class="d-flex justify-content-between align-items-center"
      >
        <span>Valor de estampilla</span>
        <span>$ {{ formatPrice(travelData.stamp_fee) }}</span>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <span>Subtotal</span>
        <span>$ {{ formatPrice(travelData.total_price) }} </span>
      </b-list-group-item>
    </b-list-group>
    <hr v-if="isActivePay">
    <b-list-group
      v-if="isActivePay"
      flush
    >
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
      >
        <h5><b>Total</b></h5>
        <h5><b>$ {{ formatPrice(totalPrice({
          total_price: parseFloat(travelData.total_price),
          transaction_fee: parseFloat(travelData.cost_per_transaction || 0),
          transaction_fee_type_payment: parseFloat(typePayment.transaction_fee || 0),
          stamp_fee: parseFloat(travelData.stamp_fee || 0)
        })) }} </b></h5>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    travelData: {
      type: Object,
      required: true,
      default: () => { },
    },
    typePayment: {
      type: Object,
      required: false,
      default: () => { },
    },
    title: {
      type: String,
      required: false,
      default: () => 'Viaje',
    },
    isActivePay: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat('de-DE', { currency: 'COP' }).format(price)
    },
    totalPrice(total) {
      console.log(total)
      const percent = total.total_price / (1-(total.transaction_fee_type_payment / 100))
      console.log(percent)
      let totalSum = percent + total.transaction_fee + total.stamp_fee
      // const percentTotal = totalSum * percent
      // if (percent !== 0) {
      //   totalSum += percentTotal
      // }
      this.$emit('total_sum', parseFloat(totalSum).toFixed(0))
      return parseFloat(totalSum).toFixed(0)
    },
  },
}
</script>
